import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo2.png";
import styles from "./usersidebar.module.css";
import { UserAuth } from "../../context/AuthContext";
import Button from "@mui/material/Button";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore, AbcOutlined, AccountCircle, Leaderboard, LibraryBooks, LogoutOutlined, LocalLibrary, Psychology } from "@mui/icons-material";
const UserSidebar = () => {
  const location = useLocation();
  const { logOut } = UserAuth();
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };
  const [openLesson, setOpenLesson] = useState(true);
  const [isSidebarFixed, setIsSidebarFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0.5) {
        setIsSidebarFixed(true);
      } else {
        setIsSidebarFixed(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={isSidebarFixed ? `${styles.sidebar} ${styles.fixed}` : styles.sidebar}>
      <div className={styles.logo}>
        <center>
          <img src={logo} width={150} alt="logo" />
        </center>
      </div>
      <div className={styles.option}>
        <List
        sx={{ width: '100%', bgcolor: '#F0F8FF' }}
        component="nav" aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={()=>setOpenLesson(!openLesson)} component={Link} to={'/dashboard'} className={styles.text} selected={location.pathname==="/dashboard"} >
            <ListItemIcon>
              <LocalLibrary />
            </ListItemIcon>
            <ListItemText primary="Learn" />
            {openLesson ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openLesson} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link}  to={'/dashboard/words'} selected={location.pathname==="/dashboard/words"}>
                <ListItemIcon>
                  <AbcOutlined />
                </ListItemIcon>
                <ListItemText primary="Words" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }} component={Link} to={'/dashboard/sentences'} selected={location.pathname==="/dashboard/sentences"}>
                <ListItemIcon>
                  <LibraryBooks />
                </ListItemIcon>
                <ListItemText primary="Sentences" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton component={Link} to={'/dashboard/tests'} className={styles.text} selected={location.pathname==="/dashboard/tests"} >
            <ListItemIcon>
              <LocalLibrary />
            </ListItemIcon>
            <ListItemText primary="Test" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/dashboard/ai'} className={styles.text} selected={location.pathname.includes("/dashboard/ai")} >
            <ListItemIcon>
              <Psychology />
            </ListItemIcon>
            <ListItemText primary="AI" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/dashboard/ranking'} className={styles.text} selected={location.pathname.includes("/dashboard/ranking")}>
            <ListItemIcon>
              <Leaderboard />
            </ListItemIcon>
            <ListItemText primary="Ranking" />
          </ListItemButton>
          <ListItemButton component={Link}  to={'/dashboard/profile'} className={styles.text} selected={location.pathname.includes("/dashboard/profile")}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Profile"  />
          </ListItemButton>
        </List>
        <div className={styles.logOut}>
          <Button startIcon={<LogoutOutlined />} onClick={handleSignOut}>
            Log Out
          </Button>
        </div>
      </div>
     </div>
  );
};
export default UserSidebar;
