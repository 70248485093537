import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../images/logo2.png";
import styles from "./adminsidebarmobile.module.css";
import { UserAuth } from "../../context/AuthContext";
import Button from "@mui/material/Button";

import {List, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import { Image } from "react-bootstrap";
import { AbcOutlined, Article, Equalizer, FontDownloadOutlined, HomeOutlined, LibraryBooksOutlined, LogoutOutlined, PersonPinOutlined, Topic } from "@mui/icons-material";
const AdminSidebarMobile = ({isOpen, onSwitch}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logOut } = UserAuth();
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
      <div className={styles.logo}>
        <center>
          <Image src={logo} width={180} alt="logo" />
        </center>
      </div>
      <div className={styles.option}>
        <List
          sx={{ width: "100%", bgcolor: "#F0F8FF" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={()=>{
              navigate("/admin")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname === "/admin"}
          >
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton
            onClick={()=>{
              navigate("/admin/words")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/words")}
          >
            <ListItemIcon>
              <FontDownloadOutlined />
            </ListItemIcon>
            <ListItemText primary="Words" />
          </ListItemButton>
          <ListItemButton
            onClick={()=>{
              navigate("/admin/sentences")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/sentences")}
          >
            <ListItemIcon>
              <AbcOutlined />
            </ListItemIcon>
            <ListItemText primary="Sentences" />
          </ListItemButton>
          <ListItemButton
            onClick={()=>{
              navigate("/admin/lessons")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/lessons")}
          >
            <ListItemIcon>
              <LibraryBooksOutlined />
            </ListItemIcon>
            <ListItemText primary="Lessons" />
          </ListItemButton>
          <ListItemButton
            onClick={()=>{
              navigate("/admin/levels")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/levels")}
          >
            <ListItemIcon>
              <Equalizer />
            </ListItemIcon>
            <ListItemText primary="Level" />
          </ListItemButton>
          <ListItemButton 
            onClick={()=>{
              navigate("/admin/topics")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/topics")}
          >
            <ListItemIcon>
              <Topic />
            </ListItemIcon>
            <ListItemText primary="Topics" />
          </ListItemButton>
          <ListItemButton 
            onClick={()=>{
              navigate("/admin/tests")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname.includes("/admin/tests")}
          >
            <ListItemIcon>
              <Article />
            </ListItemIcon>
            <ListItemText primary="Tests" />
          </ListItemButton>
          <ListItemButton
            onClick={()=>{
              navigate("/admin/users")
              onSwitch()
            }}
            className={styles.text}
            selected={location.pathname === "/admin/users"}
          >
            <ListItemIcon>
              <PersonPinOutlined />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </List>
        <div className={styles.logOut}>
          <Button startIcon={<LogoutOutlined />} onClick={handleSignOut}>
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebarMobile;
