import { TextField } from "@mui/material"
import { useState } from "react"
import { Alert, Button, Card, Container, Form } from "react-bootstrap"
import { UserAuth } from "../../context/AuthContext"
import { Link } from "react-router-dom"
import Footer from "../../components/footer/Footer"

const SignUp = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const { signUp } = UserAuth()
    const [error, setError] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== passwordConfirm) {
            return setError("Passwords do not match")
        }

        try {
            setError("")
            await signUp(email, password)
        } catch {
            setError("Failed to create an account")
        }
    }
    return (
        <div>
            <Container fluid
                className="d-flex align-items-center justify-content-center bg-secondary-subtle"
                style={{minHeight: "90vh"}}
            >
                <div className="w-100" style={{maxWidth: "400px"}}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-2">Sign Up</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <TextField className="mt-4" type="email" id="outlined-basic" fullWidth label="Email" variant="outlined" onChange={(e)=>setEmail(e.target.value)} required />
                                <TextField className="mt-4" type="password" fullWidth label="Password" variant="outlined" onChange={(e)=>setPassword(e.target.value)} required />
                                <TextField className="mt-4" type="password" fullWidth label="Password Confirmation" variant="outlined" onChange={(e)=>setPasswordConfirm(e.target.value)} required />
                                <Button className="w-100 mt-4" type="submit">Sign Up</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Already have an account? <Link to="/login">Log In</Link>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    )
}
export default SignUp