import { Link, useLocation } from "react-router-dom";
import styles from "./adminsidebar.module.css";
import { UserAuth } from "../../context/AuthContext";
import Button from "@mui/material/Button";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AbcOutlined, Article, Equalizer, FontDownloadOutlined, HomeOutlined, LibraryBooksOutlined, LogoutOutlined, PersonPinOutlined, Topic } from "@mui/icons-material";

const AdminSidebar = () => {
  const location = useLocation();
  const { logOut } = UserAuth();
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.sidebar}>
      <div className={styles.option}>
        <List sx={{ width: '100%', bgcolor: '#F0F8FF' }} component="nav" aria-labelledby="nested-list-subheader">
          <ListItemButton component={Link} to={'/admin'} className={styles.text} selected={location.pathname === "/admin"} >
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/words'} className={styles.text} selected={location.pathname.includes("/admin/words")}>
            <ListItemIcon>
              <FontDownloadOutlined />
            </ListItemIcon>
            <ListItemText primary="Words" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/sentences'} className={styles.text} selected={location.pathname.includes("/admin/sentences")}>
            <ListItemIcon>
              <AbcOutlined />
            </ListItemIcon>
            <ListItemText primary="Sentences" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/lessons'} className={styles.text} selected={location.pathname.includes("/admin/lessons")}>
            <ListItemIcon>
              <LibraryBooksOutlined />
            </ListItemIcon>
            <ListItemText primary="Lessons" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/levels'} className={styles.text} selected={location.pathname.includes("/admin/levels")}>
            <ListItemIcon>
              <Equalizer />
            </ListItemIcon>
            <ListItemText primary="Level" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/topics'} className={styles.text} selected={location.pathname === "/admin/topics"}>
            <ListItemIcon>
              <Topic />
            </ListItemIcon>
            <ListItemText primary="Topics" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/tests'} className={styles.text} selected={location.pathname === "/admin/tests"}>
            <ListItemIcon>
              <Article />
            </ListItemIcon>
            <ListItemText primary="Tests" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/admin/users'} className={styles.text} selected={location.pathname === "/admin/users"}>
            <ListItemIcon>
              <PersonPinOutlined />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        </List>
        <div className={styles.logOut}>
          <Button startIcon={<LogoutOutlined />} onClick={handleSignOut}>
            Log Out
          </Button>
        </div>
      </div>
     </div>
  );
};
export default AdminSidebar;
