import { createContext, useContext, useEffect, useState } from "react";

import { GoogleAuthProvider, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";

import { auth } from "../firebase";
import axios from "axios";
import { dataURL } from "../components/APIPort";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [provider, setProvider] = useState('')
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };
  const logOut =async () => {
    setUser(null)
    setIsLoggedIn(false)
    await signOut(auth);
  };
  const signUp =async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password)
  }
  const signInWithEmail = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
  }

  const updateResult = async () => {
    await axios.get(dataURL+"auth/"+user.uid)
    .then(response=>setUser(response.data))
  }
  const postUser = async (signedUser) => {
    const newUser = {
      uid: signedUser.uid,
      avatar: signedUser.photoURL,
      email: signedUser.email,
      name: signedUser.displayName
    };
    await axios.post(dataURL+"auth", newUser)
      .then((response) => {
        setUser(response.data)
        setIsLoggedIn(true)
      }
    )
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser != null) {
        setProvider(currentUser.providerData[0].providerId);
        postUser(currentUser);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <AuthContext.Provider value={{ googleSignIn, signInWithEmail, signUp, logOut, updateResult, user, provider, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
export const UserAuth = () => {
  return useContext(AuthContext);
};
