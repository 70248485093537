import { KeyboardBackspace } from "@mui/icons-material";
import axios from "axios";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { dataURL } from "../../../components/APIPort";

const AddTopic = () => {
    const navigate = useNavigate();
    const [topic, setTopic] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        const newTopic = {
            id: 0,
            name: topic
        }
        axios.post(dataURL+"topics", newTopic)
        .then(()=>{
            toast('Added Successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            navigate('/admin/topics');
        })
    }
    return (
        <Container>
            <Button variant="light" className="mb-2" onClick={()=>navigate('/admin/sentences')}><KeyboardBackspace/></Button>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="topic">Topic</Form.Label>
                    <Form.Control id="topic" type="text" className="mb-3" onChange={(e) => setTopic(e.target.value)} placeholder="Enter a topic"/>
                    <span></span>
                </Form.Group>
                <Button type="submit">Add</Button>
            </Form>
        </Container>
    )
}
export default AddTopic;