import Card from "react-bootstrap/Card";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import styles from "./study.module.css";
import IconButton from "@mui/material/IconButton";
import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import { KeyboardReturn, KeyboardVoice, VolumeUp } from "@mui/icons-material";
import { getHighlightedText } from "../functions/CheckAnswer";

const StudyAI = () => {
  const location = useLocation();
  const { sentences, difficulty } = location.state;
  const [result, setResult] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [paragraphColor, setParagraphColor] = useState("#000");
  let speech = new SpeechSynthesisUtterance();
  speech.voice = window.speechSynthesis.getVoices()[1];
  const {transcript, listening, resetTranscript, browserSupportsSpeechRecognition} = useSpeechRecognition();
  
  const [index, setIndex] = useState(0);
  const handleListen= () => {
    speech.text = sentences[index].content;
    window.speechSynthesis.speak(speech)
  }
  const handleSkip = () => {
    setIsButtonDisabled(true);
    setParagraphColor("red");
  };
  const handleNext = () => {
    const { highlightedWord } = getHighlightedText(transcript, sentences[index].content);
    setResult((prev) => [...prev, highlightedWord]);
    resetTranscript();
    setIsButtonDisabled(false);
    setParagraphColor("#000");
    if (index + 1 < sentences.length) {
      setIndex(index + 1);
    } else {
      if(difficulty) {
        navigate("/test/result", {
          state: {
            correct: count,
            noOfWords: sentences.length,
            results: [...result, highlightedWord],
            type: difficulty
          }
        })
      } else {
        navigate("/studyai/result", {
          state: {
            correct: count,
            noOfWords: sentences.length,
            results: [...result, highlightedWord],
          }
        })
      }
    }
  };
  const startListening = () => {
    resetTranscript();
    SpeechRecognition.startListening({ continuous: true });
  };
  const stopListening = async () => {
    await SpeechRecognition.stopListening();
    countCorrectAnswer();
  };

  const countCorrectAnswer = () => {
    const { countWord } = getHighlightedText(transcript, sentences[index].content);

    const percent = (countWord * 100) / sentences[index].content.length;
    if (percent >= 80) {
      setCount((prevState) => prevState + 1);
    }
  };
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <div className={styles.background}>
      <div className={styles.back}>
        <Button
          variant="outlined"
          onClick={() => navigate("/dashboard/ai")}
          startIcon={<KeyboardReturn />}
        >
          Back
        </Button>
      </div>
      <Card className={styles.card}>
        <Card.Header>{difficulty?"Test":"Topic"}</Card.Header>
        <Card.Body className={styles.cardBody}>
          <div className={styles.content}>
            {sentences[index] &&
              (transcript ? (
                <h4 style={{ color: "green" }}>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getHighlightedText(transcript, sentences[index].content).highlightedWord,
                      }}
                    />
                  }
                </h4>
              ) : (
                <h4 style={{ color: paragraphColor }}>{sentences[index].content}</h4>
              ))}
            <IconButton onClick={handleListen}>
              <VolumeUp />
            </IconButton>
          </div>
          <IconButton
            onTouchStart={startListening}
            onMouseDown={startListening}
            onTouchEnd={stopListening}
            onMouseUp={stopListening}
            className={styles.iconBtn}
            disabled={isButtonDisabled}
          >
            <KeyboardVoice fontSize="inherit" />
          </IconButton>
          <p>Hold to talk</p>
          <p>Microphone: {listening ? "on" : "off"}</p>
          <div className={styles.actionBtn}>
            <Button
              className={styles.btn}
              variant="contained"
              onClick={handleSkip}
            >
              Skip
            </Button>
            <Button
              className={styles.btn}
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default StudyAI;
