import { KeyboardBackspace } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { dataURL } from "../../../components/APIPort";

const AddSentence = () => {
    const navigate = useNavigate();
    const [sentence, setSentence] = useState('');
    const [lessons, setLessons] = useState([]);
    const [lesson, setLesson] = useState(0);
    const [tests, setTests] = useState([]);
    const [test, setTest] = useState(0);
    useEffect(() => {
        axios.get(dataURL+"lessons")
        .then(response => {
            setLessons(response.data);
        })
        axios.get(dataURL+"tests")
        .then(response => {
            const values = response.data.splice(1);
            setTests(values);
        })
    },[])
    const handleSubmit = (event) => {
        event.preventDefault();
        const newSentence = {
            id: 0,
            content: sentence,
            lessonId: lesson,
            testId: test
        }
        axios.post(dataURL+"sentences",newSentence)
        .then(response => {
            console.log(response.data);
            toast('Added Successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            navigate('/admin/sentences');
        })
        .catch(error=>{
            console.error("Error: ", error);
        });
    }
    return (
        <Container>
            <Button variant="light" className="mb-2" onClick={()=>navigate('/admin/sentences')}><KeyboardBackspace/></Button>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="sentence">Sentence</Form.Label>
                    <Form.Control id="sentence" className="mb-3" type="text" onChange={e=>setSentence(e.target.value)} placeholder="Enter a sentence"/>
                    <span></span>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="lesson">Lesson</Form.Label>
                    <Form.Select id="lesson" className="mb-3" aria-label="Lesson" onChange={e=>setLesson(e.target.value)}>
                        <option>Select a lesson</option>
                        {lessons.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Form.Select>
                    <span></span>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="test">Test</Form.Label>
                    <Form.Select aria-label="Test" id="test" className="mb-3" onChange={e=>setTest(e.target.value)}>
                        <option value={0}>Choose a test</option>
                        {tests.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Form.Select>
                    <span></span>
                </Form.Group>
                <Button type="submit">Add</Button>
            </Form>
        </Container>
    )
}
export default AddSentence;