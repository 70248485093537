import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "@mui/material/Button";
import styles from "./lesson.module.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Spinner from "../../../components/spinner/spinner";
import { UserAuth } from "../../../context/AuthContext";
import { ProgressBar } from "react-bootstrap";
import { dataURL } from "../../../components/APIPort";

const Lessons = () => {
  const [lessons, setLessons] = useState([]);
  const navigate = useNavigate();
  const { type } = useParams()
  const [isLoading, setIsLoading] = useState(false);
  const {user} = UserAuth();
  const [processes, setProcesses] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios.get(dataURL+"lessons")
      .then((response) => {
        setLessons(response.data);
        if (user.processes !== null){
          setProcesses(user.processes.filter(p => p.type.includes(type)))
        }
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }, [type, user]);
  const handleClick = (item) => {
    navigate("/study", { state: { lessonId: item, type: type } });
  };
  const back = () => {
    navigate("/dashboard");
  };

  const render = (
    <div className={styles.layout}>
      <div className={styles.back}>
        <Button
          variant="outlined"
          onClick={() => back()}
          startIcon={<KeyboardReturnIcon />}
        >
          Back
        </Button>
      </div>
      <div>
        {lessons.map((item, index) =>{
          const lessonProgress = processes.find(p => p.lessonId === item.id)
         return (
          <Card className={styles.card} key={index}>
            <Card.Header>
              <div className={styles.headerbox}>
                Lesson {index + 1}: {item.name}
              {lessonProgress!==undefined ? (
                <b>{lessonProgress.progress}%</b>
              ):(
                <b>0%</b>
              )}
              </div>
            </Card.Header>
            <Card.Body>
              <div className={styles.progressbar}>
                <ProgressBar variant="success" now={lessonProgress!==undefined?lessonProgress.progress:0} />
              </div>
                <Button onClick={() => handleClick(item.id)} variant="contained">Start</Button>
            </Card.Body>
          </Card>
        )})} 
      </div>
    </div>
  );
  return <>{isLoading ? <Spinner /> : render}</>;
};
export default Lessons;
