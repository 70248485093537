import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useEffect } from "react";

const CheckRoute = () => {
    const { user, isLoggedIn } = UserAuth();
    const navigate = useNavigate();

    // eslint-disable-next-line
    useEffect(() => {
      if (user) {
        if(user.role===1)
          navigate("/admin");
        else {
          navigate("/dashboard")
        }
      }
    }, [isLoggedIn]);
    
}
export default CheckRoute