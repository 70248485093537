import React from 'react'
import { Col, Row } from 'react-bootstrap'
import logo from "../../images/logo.png";
import styles from "./footer.module.css"
const Footer = () => {
  return (
    <footer className='container-fluid bg-black bg-gradient text-light'>
        <Row className='flex justify-content-center'>
            <div className={styles.image}>
                <img className='img-fluid' alt='logo' src={logo} />
            </div>
        </Row>
        <Row>
        <Col md className={styles.leftside}>
            <p>ABOUT US</p>
            <p>Connecting the world through language since 2023.</p>
            <p>From anywhere to everywhere, your words matter.</p>
        </Col>
        <Col md>
            <p>SUPPORT:</p>
            <p>24/7 Customer Service</p>
            <p>Email: phuc.dang.cit20@eiu.edu.vn</p>
            <p>Phone: 0947 283 572</p>
        </Col>
        </Row>
        <Row className='flex justify-content-center pt-3 pb-3'>
            Copyright &copy; 2024 - E-Speaking. All Right reserved.
        </Row>
    </footer>
  )
}

export default Footer
