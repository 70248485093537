import { Link, useLocation } from "react-router-dom";
import styles from "./usersidebarmobile.module.css";
import logo from "../../images/logo2.png";
import { ExpandLess, ExpandMore, AbcOutlined, AccountCircle, Leaderboard, LibraryBooks, LogoutOutlined, LocalLibrary, Psychology } from "@mui/icons-material";
import { Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { UserAuth } from "../../context/AuthContext";
import { useState } from "react";

const UserSidebar = ({isOpen, onSwitch}) => {
  const { logOut } = UserAuth();
  const location = useLocation();
  const [openLesson, setOpenLesson] = useState(false);
  const handleClick = () => {
    setOpenLesson(!openLesson);
  };
  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : ''}`}>
      <div className={styles.logo}>
        <center>
          <img src={logo} alt="logo" width={150} />
        </center>
      </div>
      <div className={styles.option}>
        <List
        sx={{ width: '100%', bgcolor: '#F0F8FF' }}
        component="nav" aria-labelledby="nested-list-subheader"
        >
          <ListItemButton component={Link} to={'/dashboard'} onClick={handleClick} className={styles.text} selected={location.pathname==="/dashboard"} >
            <ListItemIcon>
              <LocalLibrary />
            </ListItemIcon>
            <ListItemText primary="Learn" />
            {openLesson ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openLesson} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={onSwitch} component={Link} to={'/dashboard/words'} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AbcOutlined />
                </ListItemIcon>
                <ListItemText primary="Words" />
              </ListItemButton>
              <ListItemButton onClick={onSwitch} component={Link} to={'/dashboard/sentences'} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LibraryBooks />
                </ListItemIcon>
                <ListItemText primary="Sentences" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={onSwitch} component={Link} to={'/dashboard/tests'} className={styles.text} selected={location.pathname.includes("/dashboard/tests")} >
            <ListItemIcon>
              <LocalLibrary />
            </ListItemIcon>
            <ListItemText primary="Test" />
          </ListItemButton>
          <ListItemButton onClick={onSwitch} component={Link} to={'/dashboard/ai'} className={styles.text} selected={location.pathname==="/dashboard/ai"} >
            <ListItemIcon>
              <Psychology/>
            </ListItemIcon>
            <ListItemText primary="Study with AI" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/dashboard/ranking'} onClick={onSwitch} className={styles.text} selected={location.pathname.includes("/dashboard/ranking")}>
            <ListItemIcon>
              <Leaderboard />
            </ListItemIcon>
            <ListItemText primary="Ranking" />
          </ListItemButton>
          <ListItemButton component={Link} to={'/dashboard/profile'} onClick={onSwitch} className={styles.text} selected={location.pathname.includes("/dashboard/profile")}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Profile"  />
          </ListItemButton>
        </List>
        <div className={styles.logOut}>
          <Button startIcon={<LogoutOutlined />} onClick={handleSignOut}>
            Log Out
          </Button>
        </div>
      </div>
     </div>
  );
};
export default UserSidebar;
