import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "@mui/material/Button";
import styles from "./tests.module.css";
import Spinner from "../../../components/spinner/spinner";
import { UserAuth } from "../../../context/AuthContext";
import { dataURL } from "../../../components/APIPort";

const Tests = () => {
  const [tests, setTests] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {user} = UserAuth();
  const [processes, setProcesses] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios.get(dataURL+"tests")
      .then((response) => {
        const values = response.data.splice(1);
        setTests(values);
        if (user.processes !== null){
          setProcesses(user.processes.filter(p => p.type.includes("test")))
        }
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }, [user]);
  const handleClick = (item) => {
    navigate("/test", { state: {testId: item, type: "test"+item } });
  };
  const render = (
    <div className={styles.layout}>
      {tests.map((item, index) =>{
        const testProgress = processes.find(p => p.type.includes(item.id))
        return (
        <Card className={styles.card} key={index}>
          <Card.Header>
              <b>{item.name}</b>
          </Card.Header>
          <Card.Body>
          <div className={styles.progressbar}>
            Point: {testProgress!==undefined ? (<b>{testProgress.progress}</b>):(<b>0</b>)}
          </div>
            <Button onClick={() => handleClick(item.id)} variant="contained">Start</Button>
          </Card.Body>
        </Card>
      )})} 
    </div>
  );
  return <div className={styles.layout}>{isLoading ? <Spinner /> : render}</div>;
};
export default Tests;
