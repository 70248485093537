import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./result.module.css";
import Button from "@mui/material/Button";
import { UserAuth } from "../../../context/AuthContext";
import axios from "axios";
import { KeyboardReturn } from "@mui/icons-material";
import { dataURL } from "../../../components/APIPort";

const ResultTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { correct, noOfWords, results, type } = location.state;
  const {user, updateResult} = UserAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const process = {
        userUID: user.uid,
        lessonId: 0,
        progress: correct*10,
        type: type
    }
    await axios.post(dataURL+"processes", process)
    .then(response => {
        console.log(response.data);
        updateResult()
        if (type.includes("test"))
          navigate("/dashboard/tests")
        else
          navigate("/dashboard/ai")
    })
    .catch(error=>{
        console.error("Error: ", error);
    });
  }
  return (
    <div className={styles.layout}>
      <Card className={styles.card}>
        <Card.Header>
          <h2>Result</h2>
        </Card.Header>
        <Card.Body>
          <h3>You got {correct*10} points</h3>
          <h3>
            {correct}/{noOfWords} correct answers
          </h3>
          {results.map((item, index) => (
            <p key={index} style={{ color: "green" }}>
              <div dangerouslySetInnerHTML={{ __html: item }} />
            </p>
          ))}
          <Button
            variant="outlined"
            onClick={handleSubmit}
            startIcon={<KeyboardReturn />}
          >
            Back
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ResultTest;
