import { KeyboardBackspace } from "@mui/icons-material";
import axios from "axios";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { dataURL } from "../../../components/APIPort";

const EditLesson = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {data} = location.state;
    
    const [topic, setTopic] = useState(data.name);
    const handleSubmit = (e) => {
        e.preventDefault();
        const newTopic = {
            id: data.id,
            name: topic
        }
        axios.put(dataURL+"topics/"+data.id, newTopic)
        .then(response => {
            console.log(response.data);
            toast('Updated Successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            navigate('/admin/topics');
        })
        .catch(error=>{
            console.error("Error: ", error);
        });
    }
    return (
        <Container>
            <Button variant="light" className="mb-2" onClick={()=>navigate('/admin/topics')}><KeyboardBackspace/></Button>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="topic">Topic</Form.Label>
                    <Form.Control id="topic" type="text" className="mb-3" placeholder="Enter a topic" value={topic} onChange={e=>{setTopic(e.target.value)}}/>
                    <span></span>
                </Form.Group>
                <Button type="submit">Update</Button>
            </Form>
        </Container>
    )
}
export default EditLesson;