import React from "react";
import logo from "../../images/logo2.png";
import styles from "./header.module.css";
import { Link, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Fab } from "@mui/material";
import { Download } from "@mui/icons-material";
import apk from "../assets/app-release.apk"
const Header = () => {
  const location = useLocation();
  const handleDownload = (e) => {
    e.preventDefault();
    // Create an anchor element
    const link = document.createElement('a');
    link.href = apk;
    link.download = 'app-release.apk'; // Set the file name

    // Append the link to the document body and click it
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  return (
    <header>
      <Container className={location.pathname==="/"?styles.linehead:""}>
          <Link to="/">
            <img className={location.pathname==="/login"?styles.ctimg:styles.img} width={180} src={logo} alt="logo" />
          </Link>
          <div className={location.pathname==="/login"?styles.hide:""}>
            <Fab variant="extended" onClick={handleDownload}>
              <Download sx={{ mr: 1 }}/>
              Download
            </Fab>
          </div>
      </Container>
    </header>
  );
};

export default Header;
