import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import banner from "../../images/banner.png";
import communication from "../../images/communication.png";
import experience from "../../images/experience.jpg";
import community from "../../images/community.jpg"
import styles from "./home.module.css";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Header from '../../components/header/Header'
import { Image } from "react-bootstrap";
import Footer from "../../components/footer/Footer";

const Home = () => {
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    boxShadow: "1px 4px",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#000",
    },
  }));

  return (
    <div style={{backgroundColor: "#e9e9e9"}}>
      <Header/>
      <main >
        <Container className={styles.firstBlock}>
          <hr/>
          <Container>
            <Row className={styles.content}>
              <Col className={styles.left} md={6}>
                <div className={styles.slogan}>
                  <h1>Improve Your Speaking Skills with Our App</h1>
                  <p>
                    Practice speaking and gain confidence in a fun and interactive way.
                  </p>
                  <Link to="/login">
                    <ColorButton variant="contained">Get Started</ColorButton>
                  </Link>
                </div>
              </Col>
              <Col className={styles.right} md={6}>
                <Image width="80%" fluid src={banner} alt="banner"/>
              </Col>
            </Row>
            <Row className={[styles.content, styles.reverse]}>
              <Col md={6} className={styles.left}>
                <div className={styles.slogan}>
                  <h1>Empowering Effective Communication</h1>
                  <p>Whether you're a pro, a student, or just passionate about confident expression, our platform empowers you to elevate your speaking skills.</p>
                </div>
              </Col>
              <Col md={6} className={styles.right}>
                <Image src={communication} fluid alt="banner"/>
              </Col>
            </Row>
            <Row className={styles.content}>
              <Col md className={styles.left}>
                <div className={styles.slogan}>
                  <h1>Personalized Learning Experience</h1>
                  <p>We understand that clear and compelling communication is the cornerstone of success in every aspect of life. That's why we've curated a diverse range of courses, exercises, and expert insights tailored to suit your individual needs and goals.</p>
                </div>
              </Col>
              <Col md className={styles.right}>
                <Image src={experience} fluid alt="banner"/>
              </Col>
            </Row>
            <Row className={[styles.content, styles.reverse]}>
              <Col md className={styles.left}>
                <div className={styles.slogan}>
                  <h1>Join Our Community</h1>
                  <p>Come learn with us in a dynamic environment as we guide you toward becoming an expert in interpersonal communication, public speaking, and other areas. It's never been simpler to communicate oneself charismatically and clearly with E-Speaking.</p>
                </div>
              </Col>
              <Col md className={styles.right}>
                <Image src={community} fluid alt="banner"/>
              </Col>
            </Row>
          </Container>
        </Container>
      </main>
      <Footer/>
    </div>
  );
};

export default Home;
