import { Col, Container, Row } from "react-bootstrap";
import AdminSidebar from "../../components/adminSidebar/AdminSidebar";
import AdminSidebarMobile from "../../components/adminSidebar/AdminSidebarMobile";
import { Route, Routes } from "react-router-dom";
import Words from "../dashboard/words/Words";
import Sentences from "../dashboard/sentences/Sentences";
import AddSentence from "../dashboard/sentences/AddSentence";
import AdminHome from "./AdminHome";
import AddWord from "../dashboard/words/AddWord";
import AddTopic from "../dashboard/topic/AddTopic";
import EditWord from "../dashboard/words/EditWord";
import EditTopic from "../dashboard/topic/EditTopic";
import EditSentence from "../dashboard/sentences/EditSentence";
import Levels from "../dashboard/levels/Levels";
import Lessons from "../dashboard/lessons/Lessons";
import Topics from "../dashboard/topic/Topics";
import Tests from "../dashboard/tests/Tests";
import AddTest from "../dashboard/tests/AddTest";
import EditTest from "../dashboard/tests/EditTest";
import AddLesson from "../dashboard/lessons/AddLesson";
import AddLevel from "../dashboard/levels/AddLevel";
import EditLesson from "../dashboard/lessons/EditLesson";
import styles from "./admin.module.css";
import EditLevel from "../dashboard/levels/EditLevel";
import Users from "../dashboard/user_info/User";
import { useState } from "react";
import AdminHeader from "../../components/header/AdminHeader";

const Admin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenNav = () => {
    setIsOpen(true);
  };
  const handleSwitch =() => {
    setIsOpen(false);
  }
  return (
    <main>
      <div className={`${styles.overlay} ${isOpen ? styles.open : ''}`} onClick={handleSwitch}></div>
      <AdminSidebarMobile isOpen={isOpen} onSwitch={handleSwitch} />
      <AdminHeader onOpenNav={handleOpenNav}/>
      <Container fluid>
        <Row>
          <Col className={styles.sidebar} md={2}>
            <AdminSidebar />
          </Col>
          <Col className={styles.content} md={10}>
            <Routes>
              <Route path="/" element={<AdminHome />} />
              <Route path="/words" element={<Words />} />
              <Route path="/words/add" element={<AddWord />}/>
              <Route path="/words/edit/:id" element={<EditWord />}/>
              <Route path="/sentences" element={<Sentences />} />
              <Route path="/sentences/add" element={<AddSentence />}/>
              <Route path="/sentences/edit/:id" element={<EditSentence />}/>
              <Route path="/levels" element={<Levels />} />
              <Route path="/levels/add" element={<AddLevel />}/>
              <Route path="/levels/edit/:id" element={<EditLevel />}/>
              <Route path="/lessons" element={<Lessons />} />
              <Route path="/lessons/add" element={<AddLesson />}/>
              <Route path="/lessons/edit/:id" element={<EditLesson />}/>
              <Route path="/topics" element={<Topics />} />
              <Route path="/topics/add" element={<AddTopic />}/>
              <Route path="/topics/edit/:id" element={<EditTopic />}/>
              <Route path="/tests" element={<Tests />} />
              <Route path="/tests/add" element={<AddTest />}/>
              <Route path="/tests/edit/:id" element={<EditTest />}/>
              <Route path="/users" element={<Users />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </main>
  );
};
export default Admin;
