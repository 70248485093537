import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./result.module.css";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { UserAuth } from "../../../context/AuthContext";
import axios from "axios";
import { dataURL } from "../../../components/APIPort";

const Result = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { correct, noOfWords, results, lessonId, type } = location.state;
  const {user, updateResult} = UserAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const process = {
        userUID: user.uid,
        lessonId: lessonId,
        progress: (correct * 100 ) / noOfWords,
        type: type
    }
    await axios.post(dataURL+"processes", process)
    .then(response => {
        console.log(response.data);
        updateResult()
        navigate("/dashboard/"+type);
    })
    .catch(error=>{
        console.error("Error: ", error);
    });
  }
  return (
    <div className={styles.layout}>
      <Card className={styles.card}>
        <Card.Header>
          <h2>Result</h2>
        </Card.Header>
        <Card.Body>
          <h3>
            {correct}/{noOfWords} correct answers
          </h3>
          {results.map((item, index) => (
            <p key={index} style={{ color: "green" }}>
              <div dangerouslySetInnerHTML={{ __html: item }} />
            </p>
          ))}
          <Button
            variant="outlined"
            onClick={handleSubmit}
            startIcon={<KeyboardReturnIcon />}
          >
            Back
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default Result;
