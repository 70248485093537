import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import Admin from './pages/admin/Admin';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/homepage/Home';
import { AuthContextProvider } from './context/AuthContext';
import Protected from './components/Protected';
import Login from './pages/login/Login'
import HomeUser from "./pages/user/homeUser/HomeUser";
import Study from './pages/user/study/Study';
import StudyAI from './pages/user/study/StudyAI';
import Result from './pages/user/study/Result';
import ResultTopic from './pages/user/study/ResultTopic';
import CheckRoute from './components/CheckRoute';
import SignUp from './pages/signup/SignUp';
import Test from './pages/user/study/Test';
import ResultTest from './pages/user/study/ResultTest';
function App() {
  return (
    <AuthContextProvider>
      <CheckRoute/>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/admin/*" element={<Protected><Admin /></Protected>} />
        <Route path="/dashboard/*" element={<Protected><HomeUser /></Protected>} />
        <Route path="/study" element={<Protected><Study/></Protected>}/>
        <Route path='/study/result' element={<Protected><Result/></Protected>}/>
        <Route path="/test" element={<Protected><Test/></Protected>}/>
        <Route path="/test/result" element={<Protected><ResultTest/></Protected>}/>
        <Route path="/studyai" element={<Protected><StudyAI/></Protected>}/>
        <Route path='/studyai/result' element={<Protected><ResultTopic/></Protected>}/>
      </Routes>
      
    </AuthContextProvider>
  );
}

export default App;
