import { Card, Form, Modal } from 'react-bootstrap'
import styles from "./ai.module.css"
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from "../../../context/AuthContext";
import axios from 'axios'
import { Bounce, toast } from 'react-toastify'
import { dataURL } from '../../../components/APIPort'

const StudyWithAI = () => {
  const {user} = UserAuth();
  const [topic, setTopic] = useState('');
  const [easy, setEasy] = useState(0);
  const [normal, setNormal] = useState(0);
  const [hard, setHard] = useState(0);
  const [topics, setTopics] = useState([])
  const [noOfSentence, setNoOfSentence] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(()=> {
    axios.get(dataURL+"topics")
    .then(response=>{
      setTopics(response.data)
    });
    setEasy(user.processes.find(p=>p.type==="easy"))
    setNormal(user.processes.find(p=>p.type==="normal"))
    setHard(user.processes.find(p=>p.type==="hard"))
  },[user])
  const handleClose = () => {
    setShow(false);
  };
  const handleStart = () => {
    if (topic !== "" && noOfSentence !== null)
      navigate('./topic', {state: {topic: topic, noOfSentence: noOfSentence}})
    else {
      toast.error('You need to choose a topic and number of sentences to start', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }
  }
  const handleShow = () => {
    setShow(true);
  };
  const handleTest = (difficulty) => {
    navigate("./testai", {state: {difficulty}})
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Select onChange={(e)=>setTopic(e.target.value)}>
              <option value="">Select a topic</option>
              {topics.map((item, index)=> (
                <option key={index} value={item.name.toLowerCase()}>{item.name}</option>
              ))}
            </Form.Select>
            <Form.Select className="mt-4" onChange={(e)=>setNoOfSentence(e.target.value)}>
              <option value={null}>How many sentences?</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={handleStart}>
            Start
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.layout}>
        <Card className={styles.card}>
          <Card.Header>
              <b>Practice wirh topic</b>
          </Card.Header>
          <Card.Body>
            <div className={styles.progressbar}>
              Click Select to start choosing a topic
            </div>
            <Button variant="contained" onClick={handleShow}>Select</Button>
          </Card.Body>
        </Card>
        <Card className={styles.card}>
          <Card.Header>
              <b>Test with AI</b>
          </Card.Header>
          <Card.Body>
            <div className={styles.progressbar}>
              Select difficulty
            </div>
            <div className={styles.difficulty}>
              <b>Easy: ({easy?easy.progress:0} Point)</b>
              <Button variant="contained" onClick={()=>handleTest("easy")}>Start</Button>
            </div>
            <div className={styles.difficulty}>
              <b>Normal: ({normal?normal.progress:0} Point)</b>
              <Button variant="contained" onClick={()=>handleTest("normal")}>Start</Button>
            </div>
            <div className={styles.difficulty}>
              <b>Hard: ({hard?hard.progress:0} Point)</b>
              <Button variant="contained" onClick={()=>handleTest("hard")}>Start</Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default StudyWithAI
