import { KeyboardBackspace } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom'
import { Bounce, toast } from "react-toastify";
import { dataURL } from "../../../components/APIPort";
const EditSentence = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const {data} = location.state;
    const [lessons, setLessons] = useState([]);
    const [tests, setTests] = useState([]);
    const [sentence, setSentence] = useState(data.content);
    const [lesson, setLesson] = useState(data.lessonId);
    const [test, setTest] = useState(data.testId)

    useEffect(()=> {
        axios.get(dataURL+"lessons")
        .then(response=> {
            setLessons(response.data)
        });
        axios.get(dataURL+"tests")
        .then(response=> {
            const values = response.data.splice(1);
            setTests(values)
        });
    },[])
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const newSentence = {
            id: data.id,
            content: sentence,
            lessonId: lesson,
            testId: test
        }
        axios.put(dataURL+"sentences/"+data.id, newSentence)
        .then(response => {
            console.log(response.data);
            toast('Updated Successful!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
            navigate('/admin/sentences');
        })
        .catch(error=>{
            console.error("Error: ", error);
        });
    }
    return (
        <Container>
            <Button variant="light" className="mb-2" onClick={()=>navigate('/admin/sentences')}><KeyboardBackspace/></Button>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label htmlFor="sentence">Sentence</Form.Label>
                    <Form.Control id="sentence" type="text" value={sentence} placeholder="Enter a sentence" onChange={e=>setSentence(e.target.value)}/>
                    <span></span>
                </Form.Group>
                <Form.Group className="mt-2">
                    <Form.Label htmlFor="lesson">Lesson</Form.Label>
                    <Form.Select id="lesson" aria-label="Lesson" value={lesson} onChange={e=>setLesson(e.target.value)}>
                        <option>Select a lesson</option>
                        {lessons.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Form.Select>
                    <span></span>
                </Form.Group>
                <Form.Group className="mt-2 mb-3">
                    <Form.Label htmlFor="test">Test</Form.Label>
                    <Form.Select id="test" aria-label="Test" value={test} onChange={e=>setTest(e.target.value)}>
                        <option value={1}>Choose a test</option>
                        {tests.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Button type="submit">Update</Button>
            </Form>
        </Container>
    )
}
export default EditSentence;