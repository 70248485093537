import { Col, Container, Image, Row, Button, Alert } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { UserAuth } from "../../context/AuthContext";
import styles from "./login.module.css";
import loginBanner from "../../images/loginBanner.png";
import Header from "../../components/header/Header";
import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";

const HomePage = () => {
  const { googleSignIn, signInWithEmail } = UserAuth();
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const handleGoogleSignIn = async () => {
    await googleSignIn();
  };
  const handleSignIn = async (e) => {
    e.preventDefault()
    try {
      await signInWithEmail(email, password)
    } catch  {
      setError("Invalid login. Please try again")
    }
  }
  return (
    <div>
      <Container fluid className={styles.login}>
        <Row>
          <Col className={styles.left} md={7}>
            <Image src={loginBanner} fluid></Image>
          </Col>
          <Col className={styles.right} md={5}>
            <Header/>
            <div className={styles.welcome}>
              <h2>Welcome to E-Speaking</h2>
            </div>
            
            <Box
              component='form'
              onSubmit={handleSignIn}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > :not(style)': { m: 1, width: '70%' },
                '@media (max-width: 767.98px)': { 
                  '& > :not(style)': {
                    width: '90%'
                  }
                }
              }}
              noValidate
            >
              {error && <Alert variant="danger">{error}</Alert>}
              <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>setEmail(e.target.value)} />
              <TextField type="password" id="filled-basic" label="Password" variant="outlined" onChange={(e)=>setPassword(e.target.value)} />
              <Link to="/resetpassword">Forgot Password</Link>
              <Button type="submit">Sign In</Button>
              <p>Don't have an account? <Link to='/signup'>Sign Up</Link></p> 
            </Box>
            <div className={styles.or}>
              <hr className={styles.line}/>Or<hr className={styles.line}/>
            </div>
            <div className={styles.btnlogin}>
              <GoogleButton  onClick={handleGoogleSignIn} />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default HomePage;
