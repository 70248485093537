import { UserAuth } from "../../../context/AuthContext";
import style from "./profile.module.css";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";

const Profile = () => {
  const { user } = UserAuth();
  const [avatar, setAvatar] = useState(user.avatar);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className={style.layout}>
      <div className={style.centerBlock}>
        <h2>Level: <span className={style.color}>{user.level.type}</span> </h2>
        <div {...getRootProps()} className={style.dropzone}>
          <input {...getInputProps()} />
          {avatar && (
            <div className={style.avatar_preview}>
              <center>
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  className={style.avatar_image}
                  src={avatar}
                />
              </center>
            </div>
          )}
        </div>
        <h2 className="mt-3 mb-3">Point: <span className={style.color}>{user.point}</span></h2>
        <div className={style.block}>
          <h4>Name: {user.name}</h4>
        </div>
        <div className={style.block}>
          <h4>Email: {user.email}</h4>
        </div>
      </div>
    </div>
  );
};

export default Profile;
