import Button from '@mui/material/Button';
import styles from "./learn.module.css";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
function Learn() {
  return (
    <div className={styles.layout}>
      <Card className={styles.option}>
        <Card.Header className={styles.cardheader}>
          Practice with word
        </Card.Header>
        <Card.Body className={styles.word}>
          <p>Practice speaking with only one word.</p>
          <Link to="/dashboard/words" state={{type: "words"}}>
            <Button className={styles.btn} variant="contained">
              Start
            </Button>
          </Link>
        </Card.Body>
      </Card>
      <Card className={styles.option}>
        <Card.Header className={styles.cardheader}>
          Practice with sentence
        </Card.Header>
        <Card.Body className={styles.word}>
          <p>Practice speaking with sentences.</p>
          <Link to="/dashboard/sentences" state={{type: "sentences"}}>
            <Button className={styles.btn} variant="contained">
              Start
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Learn;
