import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Learn from "../learn/Learn";
import { Routes, Route } from "react-router-dom";
import UserSidebar from "../../../components/userSidebar/UserSidebar";
import UserSidebarMobile from "../../../components/userSidebar/UserSidebarMobile";
import style from "./homeuser.module.css";
import { useState } from "react";
import Lessons from "../study/Lessons";
import Ranking from "../ranking/Ranking";
import Profile from "../profile/Profile";
import { Avatar, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { UserAuth } from "../../../context/AuthContext";
import Topic from "../ai/Topic";
import Tests from "../study/Tests";
import StudyWithAI from "../ai/StudyWithAI";
import TestAI from "../ai/TestAI";

function HomeUser() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = UserAuth();
  const openNav = () => {
    setIsOpen(true);
  };
  const handleSwitch =() => {
    setIsOpen(false);
  }
  return (
    <main>
      <div className={`${style.overlay} ${isOpen ? style.open : ''}`} onClick={handleSwitch}></div>
      <UserSidebarMobile isOpen={isOpen} onSwitch={handleSwitch} />
      <div className={style.headerUs}>
        <div className={style.btnList}>
          <IconButton onClick={openNav}>
            <MenuIcon/>
          </IconButton>
          </div>
        <div className={style.adminAva}>
          <Avatar className={style.avatar} src={user.avatar} />
          <div className={style.name}>Hi {user.name}</div>
        </div>
      </div>
      <Container fluid>
        <Row>
          <Col className={style.sidebar} md={2}>
            <UserSidebar />
          </Col>
          <Col className={style.content} >
            <Routes>
              <Route path="/" element={<Learn />} />
              <Route path="/:type" element={<Lessons/>}/>
              <Route path="/tests" element={<Tests/>}/>
              <Route path="/ai" element={<StudyWithAI/>}/>
              <Route path="/ai/topic" element={<Topic/>} />
              <Route path="/ai/testai" element={<TestAI/>}/>
              <Route path="/profile" element={<Profile/>} />
              <Route path="/ranking" element={<Ranking/>}/>
            </Routes>
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default HomeUser;
