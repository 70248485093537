import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./result.module.css";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const ResultTopic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { correct, noOfWords, results } = location.state;
  const handleGoBack = () => {
    navigate("/dashboard/ai");
  }
  return (
    <div className={styles.layout}>
      <Card className={styles.card}>
        <Card.Header>
          <h2>Result</h2>
        </Card.Header>
        <Card.Body>
          <h3>
            {correct}/{noOfWords} correct answers
          </h3>
          {results.map((item, index) => (
            <p key={index} style={{ color: "green" }}>
              <div dangerouslySetInnerHTML={{ __html: item }} />
            </p>
          ))}
          <Button
            variant="outlined"
            onClick={()=>handleGoBack()}
            startIcon={<KeyboardReturnIcon />}
          >
            Back
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ResultTopic;
