import { Avatar, IconButton } from "@mui/material";
import { Menu } from '@mui/icons-material';
import logo from "../../images/logo2.png";
import style from "./adminheader.module.css";
import { UserAuth } from "../../context/AuthContext";
const AdminHeader = ({onOpenNav}) => {
    const { user } = UserAuth();
    return (
        <div className={style.headerAd}>
            <div className={style.logo}>
                <img src={logo} alt="logo" width={150}/>
            </div>
            <div className={style.btnList}>
                <IconButton onClick={onOpenNav}>
                    <Menu />
                </IconButton>
            </div>
            <div className={style.adminAva}>
                <Avatar className={style.avatar} src={user.avatar} />
                <div className={style.name}>Hi {user.name}</div>
            </div>
        </div>
    )
}

export default AdminHeader
