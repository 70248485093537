import { GoogleGenerativeAI } from "@google/generative-ai"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner/spinner";
import { Container, ListGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import styles from "./topic.module.css"
const genAI = new GoogleGenerativeAI("AIzaSyBDjVXQL5XbtXmThfbptEpqmMyKyIanxdw")

const TestAI = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isFirstRun = useRef(true);
    const location = useLocation();
    const navigate = useNavigate();
    const {difficulty} = location.state;
    const model = genAI.getGenerativeModel({model: "gemini-pro"});
    useEffect(()=> {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            async function generate() {
            const prompt = "Give me a list of 10 "+difficulty+" sentences in json format form [{id, content}] (don't contain ```json and ```)"
            const result = await model.generateContent(prompt);
            const response = result.response;
            const text = response.text();
            console.log(text);
            try {
                setList(JSON.parse(text));
                setIsLoading(false)
                } catch  {
                    generate();
                }
            }
            generate();
        }
    },[difficulty,model])
    const handleStart = () => {
      navigate("/studyai", {state: {sentences: list, difficulty}})
    }
    const render = (
      <Container>
        <div className="mt-5 mb-3">
          <center><h2>You will do the test with below sentences</h2></center>
        </div>
        {list&&(
          <ListGroup>
            {list.map((item, index)=> (
              <ListGroup.Item key={index}>{item.content}</ListGroup.Item>
            ))}
          </ListGroup>
        )}
        <div className="mt-5 mb-5">
          <center><Button variant="contained" onClick={handleStart}>Start</Button></center>
        </div>
      </Container>
    )
    return <div className={styles.layout}>{isLoading ? <Spinner /> : render}</div>;
}

export default TestAI
