export const getHighlightedText = (transcript, content) => {
    let highlighted = "";
    const speech = transcript.toLowerCase();
    const word = content.toLowerCase().replace(/[,.!?]/g, '').replace(/[-]/g, ' ');
    let j = 0;
    for (let i = 0; i < word.length; i++) {
        if (j >= content.length) break;
        if (speech[i] !== word[i]) {
            while (j < content.length && content[j].match(/[,.!?-]/g)) {
                highlighted += content[j];
                j++;
            }
            if (j < content.length) {
                highlighted += `<span style="color: red">${content[j]}</span>`;
            }
        } else {
            while (j < content.length && content[j].match(/[,.!?-]/g)) {
                highlighted += content[j];
                j++;
            }
            if (j < content.length) {
                highlighted += content[j];
            }
        }
        j++;
    }
    while (j < content.length) {
        highlighted += content[j];
        j++;
    }
    const filteredText = highlighted.replace(/<span[^>]*>.*?<\/span>/g, "");
    return {
        highlightedWord: highlighted,
        countWord: filteredText.length,
    };
  };

